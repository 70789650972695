<div class="d-flex flex-column gap-3">
    <app-page-divider [text]="'Meine nächsten Einsätze'"></app-page-divider>

    <ng-container *ngIf="isLoggedIn">
        <div class="alert alert-success">
            Eingeloggt
        </div>
    </ng-container>

    <ng-container *ngIf="isLoggedIn != undefined && !isLoggedIn">
        <div class="alert alert-danger">
            Nicht eingeloggt
        </div>
    </ng-container>

    <app-under-construction [pageName]="'Home'"></app-under-construction>

    <app-page-divider [text]="'Offene Einsätze'"></app-page-divider>
    <app-under-construction [pageName]="'Home'"></app-under-construction>

</div>
