import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AuthCallbackComponent } from '../security/auth-callback/auth-callback.component'
import { HomeComponent } from '../home/home.component'
import { InboxComponent } from '../inbox/inbox.component'
import { KnowHowComponent } from '../know-how/know-how.component'
import { PageNotFoundComponent } from './errors/page-not-found/page-not-found.component'
import { PageServerErrorComponent } from './errors/page-server-error/page-server-error.component'
import { PageNoPermissionComponent } from './errors/page-no-permission/page-no-permission.component'
import { LogoutComponent } from '../security/logout/logout.component'
import { RootComponent } from './root/root.component'
import { HelpComponent } from './information/help/help.component'
import { FaqComponent } from './information/faq/faq.component'
import { DataProtectionComponent } from './information/data-protection/data-protection.component'
import { ImprintComponent } from './information/imprint/imprint.component'
import { AuthGuard } from '../security/guards'

const routes: Routes = [
    {
        path: '',
        component: RootComponent,
        children: [
            // protected routs
            {
                path: 'assignments',
                loadChildren: () =>
                    import('../assignments/assignment.module').then(
                        (m) => m.AssignmentModule
                    ),
                canActivate: [AuthGuard],
            },

            {
                path: 'inbox',
                component: InboxComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'know-how',
                component: KnowHowComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'profile',
                loadChildren: () =>
                    import('../profile/profile.module').then(
                        (m) => m.ProfileModule
                    ),
                canActivate: [AuthGuard],
            },

            // public routs
            {
                path: 'login',
                component: AuthCallbackComponent,
            },
            {
                path: 'home',
                component: HomeComponent,
                // ToDo: Check if auth is required
                // canActivate: [AuthGuard]
            },
            {
                path: 'help',
                component: HelpComponent,
            },
            {
                path: 'faq',
                component: FaqComponent,
            },
            {
                path: 'data-protection',
                component: DataProtectionComponent,
            },
            {
                path: 'imprint',
                component: ImprintComponent,
            },
            { path: 'notFound', component: PageNotFoundComponent },
            { path: 'error', component: PageServerErrorComponent },
            { path: 'forbidden', component: PageNoPermissionComponent },
            { path: 'logout', component: LogoutComponent },
            { path: '**', redirectTo: 'home' },
        ],
    },
]
@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class MainRoutingModule {}
