export const environment = {
  production: true,
  staging: true,
  api: 'https://test.api.staff.asvz.ch/tn-api/api',
  loginPage: 'https://test.auth.asvz.ch',
  adminUrl: 'https://test.schalter.asvz.ch/tn/staff',

  identity: {
    client_id: 'c238896e-a033-4bcd-8b57-09bad18b38ce',
    authority: 'https://test.auth.asvz.ch',
    redirect_uri: 'https://test.staff.asvz.ch/tn/assets/oidc-login-redirect.html',
    scope: 'openid profile stage.staff-api stage.tn-auth',
    response_type: 'id_token token',
    post_logout_redirect_uri: 'https://test.staff.asvz.ch/tn/?postLogout=true',
    silent_redirect_uri: 'https://test.staff.asvz.ch/tn/assets/silent-redirect.html',
  },
  baseUrl: 'https://test.staff.asvz.ch/tl'
};
