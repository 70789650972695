import { Component, OnInit } from '@angular/core';
import { HeaderStateService } from '../main/headers/services/header-state.service';
import { UserAuthorizationService } from '../security/user-authorization.service';
import { AssignmentsService } from '../assignments/services/assignments.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  isLoggedIn?: boolean;

  constructor(
    private headerStateService: HeaderStateService,
    private authService: UserAuthorizationService,
    private assignmentsService: AssignmentsService
  ) {
    this.headerStateService.showDefaultHeader();
  }
  ngOnInit(): void {
    this.authService.isLoggedIn().then(x => {
      this.isLoggedIn = x;
      if (this.isLoggedIn) this.loadAssignments();
    })
  }

  loadAssignments() {
    this.assignmentsService.getPastAssignments().subscribe({
      next: () => { },
      error: () => { }
    });
  }

}
