import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from '../../../environments/environment'

@Injectable({
    providedIn: 'root',
})
export class AssignmentsService {
    constructor(private httpClient: HttpClient) {}

    getPastAssignments(): Observable<object> {
        return this.httpClient.get<object>(
            `${environment.api}/assignments/my/past`
        )
    }
}
