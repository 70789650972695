<div class="header bg-white text-dark text-center p-3 fixed-top">
    <div class="container">
        <div class="d-flex align-items-center">
            <div class="flex-even-1 text-start">
                <button *ngIf="headerStateService.returnUrl" class="btn btn-link m-0 p-0"
                    (click)="headerStateService.navigateBack()">
                    <i class="fa-solid fa-angle-left text-dark"></i>
                </button>
            </div>
            <div class="flex-even-2">
                <div>
                    <p class="p-0 m-0 text-center header-text">
                        <b>{{headerStateService.pageTitle| async}}</b>
                        <span
                            *ngIf="(headerStateService.pageSubtitle | async) != ''"><br>{{headerStateService.pageSubtitle
                            | async}}</span>
                    </p>
                </div>

            </div>
            <div class="flex-even-1 text-end">
                <button *ngIf="(headerStateService.actionIcon| async) != ''" class="btn btn-link text-dark m-0 p-0"
                    (click)="actionButtonClicked()">
                    <i [ngClass]="headerStateService.actionIcon | async"></i>
                </button>
            </div>
        </div>
    </div>
</div>