import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { UserAuthorizationService } from '../../../security/user-authorization.service';
import { HeaderStateService } from '../services/header-state.service';
import { TranslocoService } from '@jsverse/transloco';

@Component({
    selector: 'app-default-header',
    templateUrl: './default-header.component.html',
    styleUrls: ['./default-header.component.scss'],
})
export class DefaultHeaderComponent implements OnInit {
    collapsed = false;
    username: string | undefined;

    isLoggedIn?: boolean;

    isTestEnvironment = environment.staging == true;
    isDevEnvironment =
        environment.production == false && environment.staging == false;
    isProdEnvironment = environment.production == true;

    constructor(
        private authService: UserAuthorizationService
        // private translocoService: TranslocoService
    ) {}

    ngOnInit(): void {
        // var asdf = this.translocoService.translate('test');
        // console.log(asdf);

        this.authService.isLoggedIn().then((x) => {
            if (x) {
                var user = this.authService.getUser();
                this.username = user.given_name;
            }
            this.isLoggedIn = x;
        });
    }

    login() {
        this.authService.startAuthentication();
    }

    logout() {
        this.authService.logout();
    }
}
