import { NgModule } from '@angular/core';
import { DatePipe } from "@angular/common";
import { UnderConstructionComponent } from '../shared/components/under-construction/under-construction.component';
import { HomeComponent } from './home.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
    imports: [
        SharedModule
    ],
    declarations: [
        HomeComponent,
    ],
    exports: [
    ],
    providers: [
        DatePipe
    ]
})
export class HomeModule { }
